
import { Component, Vue } from "vue-property-decorator";
import { Validations } from "vuelidate-property-decorators";
import { required, numeric } from "vuelidate/lib/validators";

@Component
export default class MixinEmailTemplateEditDialogValidator extends Vue {
  @Validations()
  validations = {
    item: {
      TemplateName: { required },
      SubjectPart: { required },
      TextPart: { required },
    },
  };
  //---------------------------
  // methods
  //---------------------------

  /**
   * TemplateNameの入力エラーメッセージを返します.
   */
  get TemplateNameErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.TemplateName?.$dirty) return errors;
    !this.$v.item.TemplateName?.required && errors.push("TemplateNameを入力してください.");
    return errors;
  }
  /**
   * SubjectPartの入力エラーメッセージを返します.
   */
  get SubjectPartErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.SubjectPart?.$dirty) return errors;
    !this.$v.item.SubjectPart?.required && errors.push("SubjectPartを入力してください.");
    return errors;
  }
  /**
   * TextPartの入力エラーメッセージを返します.
   */
  get TextPartErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.TextPart?.$dirty) return errors;
    !this.$v.item.TextPart?.required && errors.push("TextPartを入力してください.");
    return errors;
  }
}
