










import SubPageTemplate from "@/components/templates/SubPageTemplate.vue";
import EmailTemplateList from "@/components/organisms/email_template/EmailTemplateList.vue";

export default {
  components: {
    SubPageTemplate,
    EmailTemplateList,
  },
};
