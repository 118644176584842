import { EmailTemplate, Option } from "@/graphql/client";
import { sdk } from "@/plugins/graphql-provider";

/**
 * メールテンプレート関する機能を提供します.
 */
class EmailTemplateService {
  /**
   * 初期状態の EmailTemplate
   */
  public get defaultEmailTemplate(): EmailTemplate {
    return {
      HtmlPart: "",
      SubjectPart: "",
      TemplateName: "",
      TextPart: "",
    } as EmailTemplate;
  }

  /**
   * EmailTemplateを検索します.
   * @param condition 検索条件
   * @returns 検索結果
   */
  public async allEmailTemplates() {
    const response = await sdk.emailTemplates();
    if (response?.emailTemplates) {
      return response.emailTemplates as EmailTemplate[];
    }
    return [] as EmailTemplate[];
  }

  /**
   * 指定したguidに一致する Option を取得します.
   * @param guid GUID
   * @returns Option
   */
  public async getEmailTemplate(templateName: string) {
    const response = await sdk.emailTemplate({
      templateName: templateName,
    });
    return response.emailTemplate as EmailTemplate;
  }

  /**
   * メールテンプレートのレンダーテストを実施します
   * @param guid GUID
   * @returns Option
   */
   public async testRenderEmailTemplate(templateName: string, templateData: string) {
     try {
      const response = await sdk.testRenderEmailTemplate({
        input: {
          TemplateName: templateName,
          TemplateData: templateData
        },
      });
      return response.testRenderEmailTemplate;
    } catch (err) {
      console.log(err);
      return err;
    }
  }

  /**
   * メールテンプレートを登録または更新します.
   * @param input メールテンプレートの更新情報
   * @returns 更新完了後のメールテンプレート
   */
  public async upsertEmailTemplate(input: EmailTemplate) {
    try {
      if (!input.TemplateName) {
        throw new Error("TemplateName is required.");
      }
      const response = await sdk.upsertEmailTemplate({
        input: {
          TemplateName: input.TemplateName,
          SubjectPart: input.SubjectPart,
          TextPart: input.TextPart,
        },
      });

      if (response?.upsertEmailTemplate) {
        return response.upsertEmailTemplate;
      }
    } catch (err) {
      console.log(err);
    }
  }
}

export default new EmailTemplateService();
