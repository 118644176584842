





































import { Component, Ref, Vue } from "vue-property-decorator";
import { NotificationState } from "@/store/modules/notification";
import emailTemplateService from "@/service/emailTemplateService";
import EmailTemplateEditDialog from "@/components/organisms/email_template/EmailTemplateEditDialog.vue";
import EmailTemplateTestDialog from "@/components/organisms/email_template/EmailTemplateTestDialog.vue";
import { EmailTemplate } from "@/graphql/client";

@Component({ components: { EmailTemplateEditDialog, EmailTemplateTestDialog } })
export default class EmailTemplateList extends Vue {
  @Ref() readonly editDialog!: EmailTemplateEditDialog;
  @Ref() readonly testDialog!: EmailTemplateTestDialog;
  //---------------------------
  // data
  //---------------------------
  items: EmailTemplate[] = [];
  headers = [
    {
      text: "TemplateName",
      value: "TemplateName",
      width: "20%",
    },
    {
      text: "SubjectPart",
      value: "SubjectPart",
    },
    {
      text: "",
      value: "testAction",
      width: "30px",
      sortable: false,
    },
    {
      text: "",
      value: "editAction",
      width: "30px",
      sortable: false,
    },
  ];

  loadingDataGrid = false;

  //---------------------------
  // mounted
  //---------------------------
  mounted(): void {
    this.fetchData();
  }

  //---------------------------
  // computed
  //---------------------------
  //---------------------------
  // methods
  //---------------------------
  async fetchData(): Promise<void> {
    this.loadingDataGrid = true;
    emailTemplateService.allEmailTemplates().then((items) => {
      this.items = items;
      this.loadingDataGrid = false;
    });
  }

  /**
   * メールテンプレート編集ダイアログ（新規追加）を表示します.
   */
  public openCreateDialog(): void {
    this.editDialog.open(emailTemplateService.defaultEmailTemplate);
  }

  /**
   * メールテンプレート編集ダイアログを表示します.
   */
  public openEditDialog(item: EmailTemplate): void {
    this.editDialog.open(item);
  }

  /**
   * メールテンプレート編集ダイアログの更新成功時.
   */
  public onUpdateSuccess(updated: EmailTemplate): void {
    //一覧にも反映
    const index = this.items.findIndex((el) => {
      return el.TemplateName === updated.TemplateName;
    });
    if (index > -1) {
      this.items.splice(index, 1, updated);
    }

    this.editDialog.close();
    NotificationState.notifySuccess("メールテンプレートを更新しました！");
  }


  /**
   * テンプレートレンダリングテストダイアログを表示
   */
  public openTestDialog(item: EmailTemplate): void {
    this.testDialog.open(item);
  }
}
