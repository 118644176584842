

























































































import { EmailTemplate } from "@/graphql/client";
import { Component, Ref, Emit, Mixins } from "vue-property-decorator";
import emailTemplateService from "@/service/emailTemplateService";
import ProgressLinear from "@/components/molecules/ProgressLinear.vue";
import MixinValidator from "@/components/mixins/email_template/MixinEmailTemplateEditDialogValidator.vue";
import MixinFormatter from "@/components/mixins/MixinFormatter.vue";

@Component({
  components: {
    ProgressLinear,
  },
})
export default class EmailTemplateTestDialog extends Mixins(
  MixinValidator,
  MixinFormatter
) {
  @Ref() readonly itemForm!: HTMLFormElement;
  //---------------------------
  // data
  //---------------------------
  isOpen = false;
  isProgressing = false;
  templateData = "{}";
  testResult = ""
  item: EmailTemplate = emailTemplateService.defaultEmailTemplate;
  valid = false;
  //---------------------------
  // mounted
  //---------------------------
  //---------------------------
  // computed
  //---------------------------
  //---------------------------
  // methods
  //---------------------------
  private async fetchData(item: EmailTemplate) {
    this.item = item;
    // this.item = emailTemplateService.defaultEmailTemplate;
    // if (item.TemplateName) {
    //   const result = await emailTemplateService.getEmailTemplate(item.TemplateName);
    //   if (result) {
    //     this.item = result;
    //   }
    // }
  }
  /**
   * ダイアログを表示します.
   */
  public open(item: EmailTemplate): void {
    this.fetchData(item);

    this.testResult = "テスト結果がここに表示されます。"
    this.isProgressing = false;
    this.isOpen = true;
  }

  /**
   * ダイアログを閉じます.
   */
  public close(): void {
    this.isProgressing = false;
    this.isOpen = false;
  }

  async doTest(): Promise<void> {
    this.testResult = "テスト結果がここに表示されます。";

    this.testResult = ( await emailTemplateService.testRenderEmailTemplate(this.item.TemplateName, this.templateData) ) as string;
  }
}
