var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"footer-props":{ itemsPerPageOptions: [20, 50, 100, -1] },"no-data-text":"メールテンプレートは登録されていません。","loading":_vm.loadingDataGrid,"loading-text":"メールテンプレートを読み込んでいます..."},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('EmailTemplateEditDialog',{ref:"editDialog",on:{"onUpdateSuccess":_vm.onUpdateSuccess}}),_c('EmailTemplateTestDialog',{ref:"testDialog"})]},proxy:true},_vm._l((_vm.headers.filter(function (header) { return header.hasOwnProperty('formatter'); }
    )),function(header){return {key:("item." + (header.value)),fn:function(ref){
    var value = ref.value;
return [_vm._v(" "+_vm._s(header.formatter(value))+" ")]}}}),{key:"item.testAction",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.openTestDialog(item)}}},[_vm._v(" bug_report ")])]}},{key:"item.editAction",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.openEditDialog(item)}}},[_vm._v(" mdi-pencil ")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }