















































































import { EmailTemplate } from "@/graphql/client";
import { Component, Ref, Emit, Mixins } from "vue-property-decorator";
import emailTemplateService from "@/service/emailTemplateService";
import ProgressLinear from "@/components/molecules/ProgressLinear.vue";
import MixinValidator from "@/components/mixins/email_template/MixinEmailTemplateEditDialogValidator.vue";
import MixinFormatter from "@/components/mixins/MixinFormatter.vue";
import moment from "moment";

@Component({
  components: {
    ProgressLinear,
  },
})
export default class EmailTemplateEditDialog extends Mixins(
  MixinValidator,
  MixinFormatter
) {
  @Ref() readonly itemForm!: HTMLFormElement;
  //---------------------------
  // data
  //---------------------------
  isOpen = false;
  isProgressing = false;
  item: EmailTemplate = emailTemplateService.defaultEmailTemplate;
  valid = false;
  //---------------------------
  // mounted
  //---------------------------
  //---------------------------
  // computed
  //---------------------------
  //---------------------------
  // methods
  //---------------------------
  private async fetchData(item: EmailTemplate) {
    this.item = emailTemplateService.defaultEmailTemplate;
    if (item.TemplateName) {
      const result = await emailTemplateService.getEmailTemplate(item.TemplateName);
      if (result) {
        this.item = result;
      } else {
        this.item.TemplateName = item.TemplateName;
      }
    } else {
      this.item.TemplateName = item.TemplateName;
    }
  }
  /**
   * ダイアログを表示します.
   */
  public open(item: EmailTemplate): void {
    this.fetchData(item);

    this.isProgressing = false;
    this.isOpen = true;
  }

  /**
   * ダイアログを閉じます.
   */
  public close(): void {
    this.isProgressing = false;
    this.isOpen = false;
  }


  /**
   * 入力内容を保存します.
   */
  save(): void {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.isProgressing = true;
      //upsert
      emailTemplateService
        .upsertEmailTemplate(this.item)
        .then((result) => {
          this.isProgressing = true;
          this.notifyUpdateSuccess(result as EmailTemplate);
        })
        .catch((err) => {
          console.error(err);
          this.notifyError("テンプレートの更新ができませんでした.");
        });
    }
  }

  /**
   * 更新成功
   */
  @Emit("onUpdateSuccess")
  public notifyUpdateSuccess(updated: EmailTemplate): EmailTemplate {
    return updated;
  }
  /**
   * 失敗
   */
  @Emit("onError")
  public notifyError(message: string): string {
    return message;
  }
}
